@import url(//db.onlinewebfonts.com/c/05428692254b58ccb090358a3dfecfff?family=Sofia+Pro);
@font-face {font-family: "Sofia Pro"; src: url("//db.onlinewebfonts.com/t/05428692254b58ccb090358a3dfecfff.eot"); src: url("//db.onlinewebfonts.com/t/05428692254b58ccb090358a3dfecfff.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/05428692254b58ccb090358a3dfecfff.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/05428692254b58ccb090358a3dfecfff.woff") format("woff"), url("//db.onlinewebfonts.com/t/05428692254b58ccb090358a3dfecfff.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/05428692254b58ccb090358a3dfecfff.svg#Sofia Pro") format("svg"); }


body {
    background-color: #181818;
    color:white;
    font-family: 'Sofia Pro';
    max-width: 1200px;
    margin: 20px auto;
    padding: 0 20px;
    max-width: 1200px;
}

/* Header */

header {
    grid-area: header;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    
}

header div {
    transform: translateY(20%);
}

.header-nav {
    display: flex;                      
    flex-direction: row;
    justify-content: flex-end;

}

.navlink {
    display:flex;
    flex-direction: row;
    align-items:center;
    margin: 0px 15px;
    padding:8px;
    text-decoration: none;
}

#navlink-home {
    color: #E40203;
}

#navlink-about {
    color: #FF8B00;
}

#navlink-projects {
    color: #FEED00;
}


.header-nav a:hover {
    text-decoration: underline overline;
    text-decoration-thickness: 2px;
}

/* Left Side Nav */

.left-sidenav {
    grid-area: left-sidenav;
    display: flex;
    flex-direction: column;
   justify-content: center;
}

.left-sidenav .icon {
    box-sizing: border-box;
    width:30px;
    height:30px;
    margin:10%;
    padding:3%;
}

.left-sidenav .icon:hover {
    border: 1px solid #2596be;
    border-radius: 10px;
}


.home-main {
    display:flex;
    flex-direction: column;
    grid-area: home-main;
    justify-content: flex-start;
    
}

.layout {
    display: grid;
    grid-template-columns: 50px 1fr 50px;
    grid-template-rows: 60px 1fr 30px;
    grid-template-areas: 
        'header header header'
        'left-sidenav home-main seg3a'
        'seg1b seg2b seg3b';
    min-height: 100vh;
    padding: 10px; 
}

.homemain-main {
    display: flex;
    flex-direction: column;
    max-width: 500px;
    padding-left:10%;
}

.homemain-text {
    display: flex;
    flex-direction: column;
    color: #BBBBBB
}

.homemain-main h3 {
    color:#E40203;
}

.homemain-main h1 {
    font-size: xx-large;
}

#text {
margin:2px;
}

.homemain-links {
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 10%;
}

.homemain-links a {
    text-decoration: none;
}

.info-container {
    display:flex;
    flex-direction: column;
    align-items: center;
    padding:10%;
    width:6.5rem;
    height:6.5rem;
    box-sizing: border-box;
}

.info-container:hover {
    background-color:inherit;
}

.info-container h2 {
   margin:0;
   padding:0;
}

.info-container img {
    width:50px;
    height:50px;
}


#item-0{
    color:#2596be;
    border: 2px solid #2596be;
    border-radius:10px;
}

#item-0:hover {
    background-color: #0b3342;
}

#item-1 {
    color: #f8c40c;
    border: 2px solid #f8c40c;
    border-radius:10px;
}

#item-1:hover {
    background-color:#443605;
}

#item-2 {
    color:#e84c3c;
    border: 2px solid #e84c3c;
    border-radius:10px;
}

#item-2:hover {
    background-color:#4b1914;
}

/* Projects */

.projects-main-container {
    margin-left:5%;
}

.project-container {
    display:flex;
    flex-direction:row;
    justify-content: space-around;
    color: #BBBBBB
}

.project {
    display:flex;
    flex-direction: column;
    box-sizing: border-box;
    padding:10%;
}

.project:hover {
    border: 2px solid #f8c40c;
    border-radius: 5%;
    background-color: #463806 ;
}

.project h3 {
    padding:0;
    margin:0;
    
}

.project h4 {
    padding:0;
    margin:0;
}

.project img {
    width:80%;
    height:110%;
}

.project-main h1 {
    color: #2596be;
    margin-bottom:5%;
    border-bottom: 2px solid #2596be;
}

/* Single project */

.project-single {
    display:flex;
    flex-direction:column;
    margin:0;
    padding:0;
    margin-left:5%;
}

.project-single h1 {
    border-bottom: 2px solid #FEED00;
}

#project-item {
    padding:0;
    margin:0;
}

#project-single-heading {
    padding:0;
    margin:0;
    color:#FEED00;
    margin-top:2%;
}

.project-single p {
    font-size: small;
    letter-spacing:1px;
    color: #BBBBBB;
}

.buttons-container {
    display:flex;
    flex-direction:row;
    margin-top:2%;
}

.buttons-container a {
    text-align: center;
    text-decoration: none;
    color:#FEED00;
    margin-right:5%;
    border: 1px solid #FEED00;
    padding:5px;
    border-radius: 5px;
}

.buttons-container a:hover {
    background-color:#444002;
}

/* About */

.about {
    margin-left: 5%;
}

.about h1 {
    border-bottom: 2px solid #FEED00;
}

.about-blurb {
    display:flex;
    flex-direction: row;
    justify-content: space-evenly;
    padding-bottom: 2%;
}

.about-blurb img {
    height:300px;
    width:300px;
    border-radius: 50%;
}

.about-blurb p a {
    text-decoration: none;
    color:#FEED00;
}

.skills h2 {
    text-align: center;
}

.skills h3 {
    text-align: center;
    color: #BBBBBB;
}

.skills h4 {
    text-align: center;
    color: #BBBBBB;
}

/* Contact */

.contact {
    display: flex;
    flex-direction: column;
    margin-left:5%;
}

.contact h1 {
    border-bottom: 2px solid #FEED00;
}

.contact h2 {
    text-align: center;
    padding:0;
    margin:0;
}

.video {
    margin-top: 2%
}

/* Mobile screen */

@media (max-width:576px) {
    .homemain-links{
        display:flex;
        flex-direction: column;
        align-items: center;
    }
    .info-container {
        width: 130px;
        height: 130px;
        margin:5px;
    }
}

@media (max-width:576px) {
    .project-container {
        display: flex;
        flex-direction: column;
    }
}

@media (max-width:576px) {
    .about-blurb {
        display: flex;
        flex-direction: column;
    }
}


